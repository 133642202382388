<template>
	<div>
		<vx-card>
			<div class="vx-row">
				<div class="vx-col w-full mb-4">
					<label class="vs-input--label">Domains*</label>
					<div v-if="registryLockDomains.length != 0">
						<template v-if="formData['domains'].length">
							<vs-chip @click="removeDomain(item)" v-for="item in formData['domains']" :key="item['domain_id']" closable>{{ item['domain'] }}</vs-chip>
						</template>
						<template v-else>
							<vs-chip>No domains selected</vs-chip>
						</template>
					</div>
					<vs-textarea v-if="registryLockDomains.length == 0" class="w-full" v-model="formData['domainsManual']" />
				</div>
				<div class="vx-col w-full mb-2">
					<vs-checkbox v-model="formData['serverHold']" class="m-0">Also affect server hold</vs-checkbox>
				</div>
				<div class="vx-col w-full mb-2">
					<label class="vs-input--label">Reason</label>
					<vs-textarea class="w-full" v-model="formData['reason']" />
				</div>
			</div>
			<div class="vx-row">
				<div class="vx-col w-full mt-4">
					<vs-button v-if="paramType != 'unlock'" ref="loadableButton" id="button-with-loading" class="mb-2 mr-4" @click="showAdditionalDataPrompt()" :disabled="user_role != 'admin' || (formData['domains'].length == 0 && formData['domainsManual'] == '')">Lock</vs-button>
					<vs-button v-if="paramType != 'lock'" ref="loadableButton2" id="button-with-loading-2" color="dark" class="mb-2" @click="openConfirm('unlock')" :disabled="user_role != 'admin' || (formData['domains'].length == 0 && formData['domainsManual'] == '')">Unlock</vs-button>
				</div>
			</div>
		</vx-card>

		<!-- ADDITIONAL DATA PROMPT -->
		<vs-prompt vs-title="Additional data" :vs-is-valid="additionalData['courtName'] != '' && additionalData['caseNo'] != '' && additionalData['link'] != ''" @vs-cancel="additionalDataCancel" @vs-accept="additionalDataConfirm" :vs-active.sync="additionalData['prompt']">
			<div class="con-exemple-prompt">
				<vs-input v-model="additionalData['courtName']" placeholder="Court Name" class="mt-3 w-full" />
				<vs-input v-model="additionalData['caseNo']" placeholder="Case No." class="mt-3 w-full" />
				<vs-input v-model="additionalData['link']" placeholder="TRO link" class="mt-3 w-full" />
			</div>
		</vs-prompt>
	</div>
</template>

<script>

	export default{
		data() {
			return {
				formData: {
					reason: '',
					domains: [],
					serverHold: false,
					domainsManual: ''
				},
				additionalData: {
					courtName: '',
					caseNo: '',
					link: '',
					prompt: false
				}
			}
		},
		watch: {
			
		},
		computed: {
			registryLockDomains: function(){
				return this.$store.getters.getRegistryLockDomains;
			},
			paramType() {
				return this.$route.params.type ? this.$route.params.type : null;
			},
			user_role() {
				return JSON.parse(localStorage.getItem('userDetails')).role;
			},
		},
		methods: {
			showButtonLoading(){
				if(document.getElementById("button-with-loading")) {
					this.$vs.loading({
						background: 'primary',
						color: '#FFFFFF',
						container: '#button-with-loading',
						scale: 0.45
					});
				}
				if(document.getElementById("button-with-loading-2")) {
					this.$vs.loading({
						background: 'dark',
						color: '#FFFFFF',
						container: '#button-with-loading-2',
						scale: 0.45
					});	
				}
			},
			hideButtonLoading(){
				if(document.getElementById("button-with-loading")) {
					this.$vs.loading.close("#button-with-loading > .con-vs-loading");
				}
				if(document.getElementById("button-with-loading-2")) {
					this.$vs.loading.close("#button-with-loading-2 > .con-vs-loading");
				}
			},
			showAdditionalDataPrompt() {
				this.additionalData['prompt'] = true;
			},
			additionalDataCancel() {
				this.additionalData['prompt'] = false;
				this.additionalData['courtName'] = '';
				this.additionalData['caseNo'] = '';
				this.additionalData['link'] = '';
			},
			additionalDataConfirm() {
				this.openConfirm('lock');
			},
			openConfirm(type) {
				if(type == 'lock') {
					// Open confirm popup
					this.$vs.dialog({
						type: 'confirm',
						color: 'primary',
						title: 'Confirm',
						text: 'Lock the selected domains?',
						accept: this.lockDomains
					});
				}
				else if(type == 'unlock') {
					// Open confirm popup
					this.$vs.dialog({
						type: 'confirm',
						color: 'primary',
						title: 'Confirm',
						text: 'Unlock the selected domains?',
						accept: this.unlockDomains
					});
				}
			},
			lockDomains() {
				let self = this,
					postURL;
				// Show loader
				this.showButtonLoading();
				// Assign Post URL
				if(this.formData['domainsManual'] != '') {
					postURL = '/lock-domains-manual';
				}
				else {
					postURL = '/lock-domains';
				}
				// Post Axios Call
				this.$axiosSecure.post(postURL, {
					...self.formData,
					...self.additionalData,
					watchtower_email: JSON.parse(localStorage.getItem('userDetails')).email
				})
				.then((response) => {
					if (response.data.errorArray.length) {
						// Open info popup
						self.$vs.dialog({
							type: 'confirm',
							color: 'primary',
							title: 'Please Note',
							text: 'The following domains were erroneous & hence ignored: '+response.data.errorArray.join(', '),
							accept: self.showLockSuccess,
							acceptText: 'Ok',
							buttonCancel: 'hidden'
						});
					}
					else {
						// Success Confirmation
						self.showLockSuccess();
					}
				})
				.catch((error) => {
					// Hide loader
					this.hideButtonLoading();
					// Error notification
					self.$vs.notify({
						color: 'danger',
						title: 'Something went wrong',
						text: 'Please contact the server admin'
					});

					console.log(error);
				});
			},
			showLockSuccess() {
				// Confimation notification
				this.$vs.notify({
					color: 'success',
					title: 'Lock success',
					text: 'The selected domains have been queued for lock'
				});
				
				// Hide loader
				this.hideButtonLoading();
				this.$router.push({ path: '/action-review' });
			},
			unlockDomains() {
				let self = this,
					postURL;
				// Show loader
				this.showButtonLoading();
				// Assign Post URL
				if(this.formData['domainsManual'] != '') {
					postURL = '/unlock-domains-manual';
				}
				else {
					postURL = '/unlock-domains';
				}
				// Post Axios Call
				this.$axiosSecure.post(postURL, {
					...self.formData,
					watchtower_email: JSON.parse(localStorage.getItem('userDetails')).email
				})
				.then((response) => {
					if (response.data.errorArray.length) {
						// Open info popup
						self.$vs.dialog({
							type: 'confirm',
							color: 'primary',
							title: 'Please Note',
							text: 'The following domains were erroneous & hence ignored: '+response.data.errorArray.join(', '),
							accept: self.showUnlockSuccess,
							acceptText: 'Ok',
							buttonCancel: 'hidden'
						});
					}
					else {
						// Success Confirmation
						self.showUnlockSuccess();
					}
				})
				.catch((error) => {
					// Hide loader
					this.hideButtonLoading();
					// Error notification
					self.$vs.notify({
						color: 'danger',
						title: 'Something went wrong',
						text: 'Please contact the server admin'
					});

					console.log(error);
				});
			},
			showUnlockSuccess() {
				// Confimation notification
				this.$vs.notify({
					color: 'success',
					title: 'Unlock success',
					text: 'The selected domains have been queued for unlock'
				});
				
				// Hide loader
				this.hideButtonLoading();
				this.$router.push({ path: '/registry-lock-list' });
			},
			removeDomain(item) {
				this.removeFromArray(this.formData['domains'], item, 'domain_id');
			},
		},
		beforeMount() {
			// Redirect to home if registryLockDomains is empty
			// if (this.registryLockDomains.length == 0) {
			// 	this.$router.push({ path: '/' })
			// }
		},
		mounted() {
			// Prepopulate form
			this.formData['domains'] = this.registryLockDomains.map(x => x);
		},
		destroyed() {
			// Clear Takedown Domains
			this.$store.dispatch("clearRegistryLockDomains");
		}
	}
</script>
